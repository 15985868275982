import { motion } from "framer-motion";

export default function SkillCard(props){
    return(
        <motion.div whileHover={{scale:1.1}} className="h-32 w-32 flex flex-col justify-center items-center">
            <h1 className="text-2xl font-bold text-center mb-1">{props.name}</h1>
            <img src={props.src} alt={props.name + " Icon"} className=""/>
        </motion.div>
    );
};
