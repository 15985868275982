import Github from "../images/githubSocial.svg";
import Linkedin from "../images/linkedinSocial.svg"; 
import Email from "../images/email.svg";
import Notion from "../images/notion.svg"
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

export default function Socials(){
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
          const triggerDistance = 120; // Adjust this value to control when the element becomes visible
          const scrollDistance = window.scrollY || document.documentElement.scrollTop;
    
          if (scrollDistance >= triggerDistance) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    

    return(
        <div className={`${isVisible ? "":"hidden"} flex flex-col fixed bottom-0 left-0 m-4`}>
            <motion.a
              href="https://www.notion.so/@schuylergood"
              target="_blank"
              className="w-16 h-16 rounded-full  flex justify-center items-center m-2"
              whileHover={{ scale: 1.1 }}
            >
              <img className="w-10 h-10" alt="notion icon" src={Notion}/>
            </motion.a>
            <motion.a
                href="https://github.com/SchuylerGood"
                target="_blank"
                className="w-16 h-16 rounded-full  flex justify-center items-center m-2"
                whileHover={{ scale: 1.1 }}
            >
                <img className="w-10 h-10" alt="github icon" src={Github}/>
            </motion.a>
            <motion.a
                href="https://www.linkedin.com/in/schuylergood/"
                target="_blank"
                className="w-16 h-16 rounded-full  flex justify-center items-center m-2"
                whileHover={{ scale: 1.1 }}
            >
                <img className="w-10 h-10" alt="linkedin icon" src={Linkedin}/>
            </motion.a>
            <motion.a
                href="mailto:schuylergood@gmail.com"
                target="_blank"
                className="w-16 h-16 rounded-full  flex justify-center items-center m-2"
                whileHover={{ scale: 1.1 }}
            >
                <img className="w-10 h-10" alt="envelope icon" src={Email}/>
            </motion.a>
        </div>
    );
};