import "../index.css"

export default function Splashscreen(){
    return(
        <div className="bg-gradient w-full h-screen text-white font-bold bg-slate-500 flex justify-center items-center">
            <div className="flex flex-col">
                <h1 className="text-5xl md:text-6xl lg:text-8xl">Hello.</h1>
                <h1 className="text-5xl md:text-6xl lg:text-8xl">I'm Schuyler!</h1>
            </div>
        </div>
    );
};