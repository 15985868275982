import React from 'react';
import Splashscreen from './components/Splashscreen';
import About from './components/About';
import Skills from './components/Skills';
import Work from './components/Work';
import Clubs from './components/Clubs';
import Projects from './components/Projects';
import Games from './components/Games';
import Socials from './components/Socials';

function App() {
  return (
    <div className="bg-slate-900">
      <Splashscreen />
      <About />
      <Skills />
      <Work />
      <Clubs />
      <Projects />
      <Games />
      <Socials />
    </div>
  );
}

export default App;
