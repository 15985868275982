import LinkIcon from '../images/LinkIndigoIcon.svg';
import { motion } from 'framer-motion';

export default function ClubCard(props) {
    return(
        <div className="flex flex-row w-[300px] lg:w-[450px] bg-slate-700 rounded-2xl m-4 ">
            <div className="flex flex-col w-full">
                <img className="w-full h-[175px] lg:h-[262px] rounded-t-2xl" src={props.image} alt={props.title} />
                <div className="flex flex-col justify-between h-full p-5">
                    <div>
                        <h1 className="text-white text-2xl font-medium title-font mb-4">{props.title}</h1>
                        <p className="text-white leading-relaxed text-base">{props.description}</p>
                    </div>
                    <div className="flex flex-row w-full h-fit mt-5">
                        { props.link !== null ? 
                            <motion.a 
                                href={props.link} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-indigo-400 inline-flex items-center m-2"
                                whileHover={{ scale: 1.1, cursor: 'pointer' }}
                            >
                                <img src={LinkIcon} alt="Link Icon" className="w-5 h-5 mx-1" />
                                <span>Link</span>
                            </motion.a>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};