//import GithubIcon from '../images/GithubWhiteIcon.svg';
// import LinkIcon from '../images/LinkWhiteIcon.svg';
// import ItchIcon from '../images/ItchWhiteIcon.svg';
import GithubIcon from '../images/GithubIndigoIcon.svg';
import LinkIcon from '../images/LinkIndigoIcon.svg';
import ItchIcon from '../images/ItchIndigoIcon.svg';
import { motion } from 'framer-motion';
export default function ProjectCard(props) {
    return (
        <div className="flex flex-row w-[300px] lg:w-[450px] bg-slate-700 rounded-2xl m-4 ">
            <div className="flex flex-col w-full">
                <img className="w-full h-[175px] lg:h-[262px] rounded-t-2xl" src={props.image} alt={props.title} />
                <div className="flex flex-col justify-between h-full p-5">
                    <div>
                        <h1 className="text-white text-2xl font-medium title-font mb-4">{props.title}</h1>
                        <div className=''>
                            { props.chips.map((tech, index) => (
                                <span key={index} className="inline-flex items-center justify-center px-4 py-2 mr-2 mb-2 text-sm font-medium leading-none text-white bg-indigo-400 rounded-full">{tech}</span>
                            ))}
                        </div>
                        <p className="text-white leading-relaxed text-base">{props.description}</p>
                    </div>
                    
                    <div className="flex flex-row w-full h-fit mt-5">
                        { props.github !== null ? 
                            <motion.a 
                                href={props.github} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-indigo-400 inline-flex items-center m-2"
                                whileHover={{ scale: 1.1, cursor: 'pointer' }}
                            >
                                <img src={GithubIcon} alt="Github Icon" className="w-5 h-5 mx-1" />
                                <span>GitHub</span>
                            </motion.a>
                            :
                            <></>
                        }
                        { props.link !== null ? 
                            <motion.a 
                                href={props.link} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-indigo-400 inline-flex items-center m-2"
                                whileHover={{ scale: 1.1, cursor: 'pointer' }}
                            >
                                <img src={LinkIcon} alt="Link Icon" className="w-5 h-5 mx-1" />
                                <span>Link</span>
                            </motion.a>
                            :
                            <></>
                        }
                        { props.itchIO !== null ? 
                            <motion.a 
                                href={props.itchIO} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="text-indigo-400 inline-flex items-center m-2"
                                whileHover={{ scale: 1.1, cursor: 'pointer' }}
                            >
                                <img src={ItchIcon} alt="Itch Icon" className="w-5 h-5 mx-1" />
                                <span>Itch.io</span>
                            </motion.a>
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};