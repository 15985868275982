import React from "react";
import ClubCard from "./ClubCard";
import QWEB from "../images/QWEB.png";
import QHACKS from "../images/QHACKS.png";
import QGDC from "../images/QGDC.png";

export default function Clubs() {
    
    const ClubData = {
        QWeb:{
            title:"Queen's Web Development Club",
            description:"Currently the Co-Chair for the club organizing events and workshops for over 500 students to learn the fundamentals of web development.",
            image:QWEB,
            chips:["ReactJS", "NodeJS", "MongoDB", "ExpressJS", "Figma", "HTML", "CSS", "JavaScript"],
            link:"https://main--qweb-website.netlify.app/"
        },
        QHacks:{
            title:"QHacks Hackathon",
            description:"Currently the Frontend Technology Director for the hackathon, leading a team of 3 to build the website for the hackathon.",
            image:QHACKS,
            chips:["NextJS", "Tailwind", "ReactJS", "JavaScript", "Figma", "HTML", "CSS", ],
            link:"https://qhacks.io/"
        },
        QGDC:{
            title:"Queen's Game Development Club",
            description:"Currently a general member in the club, learning the fundamentals of game development. Won the 2022 Game Jam in a team of 4.",
            image:QGDC,
            chips:["Unity", "C#", "Aseprite"],
            link:"https://qgdc.wordpress.com/"
        }
    };

    return(
        <section id="clubs" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">CLUBS</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Campus Extracurriculars</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Im very active on campus and I love being a part of it</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    <ClubCard 
                        title={ClubData.QWeb.title} 
                        description={ClubData.QWeb.description} 
                        image={ClubData.QWeb.image} 
                        chips={ClubData.QWeb.chips}
                        link={ClubData.QWeb.link}
                    />
                    <ClubCard 
                        title={ClubData.QHacks.title} 
                        description={ClubData.QHacks.description} 
                        image={ClubData.QHacks.image} 
                        chips={ClubData.QHacks.chips}
                        link={ClubData.QHacks.link}
                    />
                    <ClubCard 
                        title={ClubData.QGDC.title} 
                        description={ClubData.QGDC.description} 
                        image={ClubData.QGDC.image} 
                        chips={ClubData.QGDC.chips}
                        link={ClubData.QGDC.link}
                    />
                </div>
            </div>
        </section>
    );
}