import React from "react";
import ProjectCard from "./ProjectCard";
import Stylit from "../images/Stylit.png";
import Wishlist from "../images/Discord.png";
import Portfolio from "../images/Portfolio.png";
import NChessSolver from "../images/NChessSolver.png";
import QHacks2023 from "../images/QHACKS2023.png";
import QHacks2024 from "../images/QHACKS2024.png";
import NotionJobTracker from "../images/NotionV2.png";
import JobTracker from "../images/JobTracker.png";

export default function Projects() {

    const ProjectData = {
        Stylit:{
            title:"Stylit",
            description:"Stylit is an AI Hairstyle recommendation mobile app, I worked in a team of 4 to create this app for the 2023 Qhacks Hackathon. We used facial recognition to detect the user's face and used machine learning to recommend hairstyles based on their facial structure",
            image:Stylit,
            chips:["AI","Deep/Machine Learning", "React Native", "JavaScript", "Figma", "MLP", "Python", "Facial Recognition","Express JS"],
            githubLink:"https://github.com/DylanWalker1320/Stylit",
            itchIOLink:null,
            liveLink: null
        },
        Qhacks2024:{
            title:"Qhacks 2024 Website",
            description:"I led a team of 3 officers to designed and developed the Qhacks 2024 website using.",
            image:QHacks2024,
            chips:["NextJS", "Tailwind CSS", "JavaScript", "Figma"],
            githubLink:"https://github.com/qhacks/qhacks-website-2024",
            itchIOLink:null,
            liveLink: "https://qhacks.io/"
        },
        Wishlist:{
            title:"Wishlist Discord Bot",
            description:"This discord bot was created for the members of any discord server to create colaborative wishlists using any valid newegg product link. This was my first ever project using the discord API.",
            image:Wishlist,
            chips:["Python", "Discord API", "Web Scraping", "Beautiful Soup"],
            githubLink:"https://github.com/SchuylerGood/Whishlist-Bot",
            itchIOLink:null,
            liveLink: null
        },
        Portfolio:{
            title:"Portfolio Website",
            description:"This website was created to showcase my projects and skills. It was created using React and Tailwind CSS.",
            image:Portfolio,
            chips:["React", "Tailwind CSS", "JavaScript", "Figma"],
            githubLink:"https://github.com/SchuylerGood/personal-website-version3",
            itchIOLink:null,
            liveLink: "https://schuylergood.com"
        },
        Qhacks2023:{
            title:"Qhacks 2023 Website",
            description:"I worked in a team of 3 officers to design and develop the Qhacks 2023 website.",
            image:QHacks2023,
            chips:["React", "HTML", "CSS", "ChakraUI", "JavaScript", "Figma"],
            githubLink:"https://github.com/qhacks/qhacks-website-2023",
            itchIOLink:null,
            liveLink: null
        },
        NChessSolver:{
            title:"N-Chess Solver",
            description:"I worked in a team of 4 for a school project using predicate logic where we created a program that could solve the N-Queen's problem (TODO:add link) with King, Queen, Rook, Bishop, and Horse pieces.",
            image:NChessSolver,
            chips:["Python", "Predicate Logic", "Backtracking"],
            githubLink:"https://github.com/SchuylerGood/n-chess-simulation",
            itchIOLink:null,
            liveLink: null
        },
        NotionJobTracker:{
            title:"Notion Job Tracker",
            description:"The Notion Job Tracker is a way to organize your job applications, collect the data, visualize and predict certain metrics. This project includes a Notion Template, along with a program using the Notion API.",
            image:NotionJobTracker,
            chips:["Python", "Flask", "Pandas", "MatPlotLib", "CLI", "Next JS", "TypeScript", "Tailwind"],
            githubLink:"https://github.com/SchuylerGood/Application-Visualizer-Notion-API",
            itchIOLink:null,
            liveLink: null
        },
        JobTracker:{
            title:"Job Tracker",
            description:"Built a job search and tracking app with Prisma ORM, tRPC, NextJS, and Tailwind to help users manage their job hunt. Added features like user authentication and CRUD operations using Clerk API and ngrok. Also, used Cheerio and Axios to scrape job postings from different websites. In the future I will add AI powered web scraping",
            image:JobTracker,
            chips:["NextJs", "TypeScript", "Prisma", "tRPC", "SupaBase", "Axios", "Cheerio", "Ngrok", "Webhooks"],
            githubLink:"https://github.com/SchuylerGood/job-tracker",
            itchIOLink:null,
            liveLink: "https://job-tracker-black.vercel.app/"
        }
    };

    return(
        <section id="projects" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">PROJECTS</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Projects I'm proud to present</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Some of my most recent projects that I would love to showcase</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    <ProjectCard
                        title={ProjectData.JobTracker.title}
                        description={ProjectData.JobTracker.description}
                        image={ProjectData.JobTracker.image}
                        chips={ProjectData.JobTracker.chips}
                        github={ProjectData.JobTracker.githubLink}
                        link={ProjectData.JobTracker.liveLink}
                        itchIO={ProjectData.JobTracker.itchIOLink}
                    />
                    <ProjectCard 
                        title={ProjectData.NotionJobTracker.title} 
                        description={ProjectData.NotionJobTracker.description} 
                        image={ProjectData.NotionJobTracker.image} 
                        chips={ProjectData.NotionJobTracker.chips}
                        github={ProjectData.NotionJobTracker.githubLink}
                        link={ProjectData.NotionJobTracker.liveLink}
                        itchIO={ProjectData.NotionJobTracker.itchIOLink}
                    />
                    <ProjectCard 
                        title={ProjectData.Stylit.title} 
                        description={ProjectData.Stylit.description} 
                        image={ProjectData.Stylit.image} 
                        chips={ProjectData.Stylit.chips}
                        github={ProjectData.Stylit.githubLink}
                        link={ProjectData.Stylit.liveLink}
                        itchIO={ProjectData.Stylit.itchIOLink}
                    />
                    <ProjectCard 
                        title={ProjectData.Wishlist.title} 
                        description={ProjectData.Wishlist.description} 
                        image={ProjectData.Wishlist.image} 
                        chips={ProjectData.Wishlist.chips}
                        github={ProjectData.Wishlist.githubLink}
                        link={ProjectData.Wishlist.liveLink}
                        itchIO={ProjectData.Wishlist.itchIOLink}
                    />
                    <ProjectCard
                        title={ProjectData.Qhacks2024.title} 
                        description={ProjectData.Qhacks2024.description} 
                        image={ProjectData.Qhacks2024.image} 
                        chips={ProjectData.Qhacks2024.chips}
                        github={ProjectData.Qhacks2024.githubLink}
                        link={ProjectData.Qhacks2024.liveLink}
                        itchIO={ProjectData.Qhacks2024.itchIOLink}
                    />
                    <ProjectCard
                        title={ProjectData.Qhacks2023.title} 
                        description={ProjectData.Qhacks2023.description} 
                        image={ProjectData.Qhacks2023.image} 
                        chips={ProjectData.Qhacks2023.chips}
                        github={ProjectData.Qhacks2023.githubLink}
                        link={ProjectData.Qhacks2023.liveLink}
                        itchIO={ProjectData.Qhacks2023.itchIOLink}
                    />
                    <ProjectCard 
                        title={ProjectData.Portfolio.title} 
                        description={ProjectData.Portfolio.description} 
                        image={ProjectData.Portfolio.image} 
                        chips={ProjectData.Portfolio.chips}
                        github={ProjectData.Portfolio.githubLink}
                        link={ProjectData.Portfolio.liveLink}
                        itchIO={ProjectData.Portfolio.itchIOLink}
                    />
                    <ProjectCard 
                        title={ProjectData.NChessSolver.title} 
                        description={ProjectData.NChessSolver.description} 
                        image={ProjectData.NChessSolver.image} 
                        chips={ProjectData.NChessSolver.chips}
                        github={ProjectData.NChessSolver.githubLink}
                        link={ProjectData.NChessSolver.liveLink}
                        itchIO={ProjectData.NChessSolver.itchIOLink}
                    />
                </div>
            </div>
        </section>
    );
}