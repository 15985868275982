import React from "react";
import SkillCard from "./SkillCard";

import HTMLIcon from "../images/HTML.svg";
import CSSIcon from "../images/CSS.svg";
import JavaScriptIcon from "../images/JavaScript.svg";
import ReactIcon from "../images/React.svg";
import NodeIcon from "../images/Node.svg";
import NativeIcon from "../images/React.svg";
import PythonIcon from "../images/Python.svg";
import JavaIcon from "../images/Java.svg";
import CIcon from "../images/C.svg";
import CPlusIcon from "../images/C++.svg";
import CSharpIcon from "../images/CSharp.svg";
import PHPIcon from "../images/PHP.svg";
import TailwindIcon from "../images/Tailwind.svg";
import TypeScriptIcon from "../images/Typescript.svg";
import NextIcon from "../images/Next.svg";
import GitHubIcon from "../images/Github.svg";
import UnityIcon from "../images/Unity.svg";
import FigmaIcon from "../images/Figma.svg";

export default function Skills(){

    const SkillsData = {
        "html":{
            "name":"HTML",
            "image":HTMLIcon
        },
        "css":{
            "name":"CSS",
            "image":CSSIcon
        },
        "javascript":{
            "name":"JavaScript",
            "image":JavaScriptIcon
        },
        "react":{
            "name":"React",
            "image":ReactIcon
        },
        "node":{
            "name":"Node",
            "image":NodeIcon
        },
        "native":{
            "name":"Native",
            "image":NativeIcon
        },
        "python":{
            "name":"Python",
            "image":PythonIcon
        },
        "java":{
            "name":"Java",
            "image":JavaIcon
        },
        "c":{
            "name":"C",
            "image":CIcon
        },
        "cplus":{
            "name":"C++",
            "image":CPlusIcon
        },
        "csharp":{
            "name":"C#",
            "image":CSharpIcon
        },
        "php":{
            "name":"PHP",
            "image":PHPIcon
        },
        "tailwind":{
            "name":"Tailwind",
            "image":TailwindIcon
        },
        "typescript":{
            "name":"TypeScript",
            "image":TypeScriptIcon
        },
        "next":{
            "name":"Next",
            "image":NextIcon
        },
        "github":{
            "name":"GitHub",
            "image":GitHubIcon
        },
        "unity":{
            "name":"Unity",
            "image":UnityIcon
        },
        "figma":{
            "name":"Figma",
            "image":FigmaIcon
        },
    }

    return(
        <div className="flex flex-col items-center h-fit text-white mt-40">
            <div className="w-[90%] lg:w-[80%] 2xl:w-[65%] h-fit">
                <div className="flex flex-col w-full mb-20 text-center">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">SKILLS</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">My Skillset</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Always learning new languages, heres what I know right now</p>
                </div>
                {/* Main Card */}
                <div className="flex flex-col w-full h-fit bg-slate-700 rounded-2xl">
                    <div className="flex flex-row justify-center mx-0 lg:mx-20 my-20 flex-wrap">
                        <SkillCard name={SkillsData.html.name} src={SkillsData.html.image}/>
                        <SkillCard name={SkillsData.css.name} src={SkillsData.css.image}/>
                        <SkillCard name={SkillsData.javascript.name} src={SkillsData.javascript.image}/>
                        <SkillCard name={SkillsData.next.name} src={SkillsData.next.image}/>
                        <SkillCard name={SkillsData.react.name} src={SkillsData.react.image}/>
                        <SkillCard name={SkillsData.node.name} src={SkillsData.node.image}/>
                        <SkillCard name={SkillsData.native.name} src={SkillsData.native.image}/>
                        <SkillCard name={SkillsData.python.name} src={SkillsData.python.image}/>
                        <SkillCard name={SkillsData.java.name} src={SkillsData.java.image}/>
                        <SkillCard name={SkillsData.c.name} src={SkillsData.c.image}/>
                        <SkillCard name={SkillsData.cplus.name} src={SkillsData.cplus.image}/>
                        <SkillCard name={SkillsData.csharp.name} src={SkillsData.csharp.image}/>
                        <SkillCard name={SkillsData.php.name} src={SkillsData.php.image}/>
                        <SkillCard name={SkillsData.tailwind.name} src={SkillsData.tailwind.image}/>
                        <SkillCard name={SkillsData.typescript.name} src={SkillsData.typescript.image}/>
                        <SkillCard name={SkillsData.github.name} src={SkillsData.github.image}/>
                        <SkillCard name={SkillsData.unity.name} src={SkillsData.unity.image}/>
                        <SkillCard name={SkillsData.figma.name} src={SkillsData.figma.image}/>
                    </div>
                </div>
            </div>
        </div>
    );
};