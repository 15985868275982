import React from "react";
import WorkCard from "./WorkCard";
import BirchbarkHealth from "../images/BirchbarkHealth.png";
import LinkUp from "../images/LinkUp.png";
import Erickson from "../images/Erickson.png";
import Sabre from "../images/Sabre.png";

export default function Work() {

    const WorkData = {
        BirchbarkHealth:{
            title:"Birchbark Health",
            position:"Software Engineer",
            description:"Founding Software Engineer at an early stage Healthcare startup offering a highly tailored and streamlined healthcare experience for First Nations Peoples and healthcare providers.",
            image:BirchbarkHealth,
            link:"https://www.linkedin.com/company/symptom360/about/",
            chips:["NextJS", "TypeScript", "Figma", "HTML", "CSS", "Tailwind", "JavaScript", "tRPC", "Prisma", ]
        },
        LinkUp:{
            title:"LinkUp",
            position:"Web Programming Intern",
            description:"Web Programming Intern at a job data acquisition company. Employed advanced PHP, Regex, and Proxy techniques to successfully extract data from 22,000 job listings and over 200 companies",
            image:LinkUp,
            link:"https://www.linkup.com",
            chips:["PHP", "Regex", "Proxy"]
        },
        Erickson:{
            title:"Erickson Building Inc.",
            position:"Web Development Contractor",
            description:"Designed and deployed a business website, leveraging Figma, ReactJS, HTML, and CSS for an optimal user experience, resulting in significant increase of sales",
            image:Erickson,
            link:"https://www.ericksonbuildinginc.com",
            chips:["Figma", "HTML", "CSS", "JavaScript"]
        },
        Sabre:{
            title:"Sabre",
            position:"Frontend Software Developer Intern",
            description:"I am an incoming Frontend Software Developer Intern at Sabre for the summer of 2024, I will be working in an Agile team to develop internal software tools.",
            image:Sabre,
            link:"https://www.sabre.com",
            chips:["SolidJS","ReactJS","JavaScript","CSS","HTML"]
        }
    };

    return (
        <section id="work" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">WORK</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Recent Experiences</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Here are some of my recent experiences.</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    <WorkCard
                        title={WorkData.Sabre.title}
                        position={WorkData.Sabre.position} 
                        description={WorkData.Sabre.description} 
                        image={WorkData.Sabre.image} 
                        link={WorkData.Sabre.link}
                        chips={WorkData.Sabre.chips}
                    />
                    <WorkCard 
                        title={WorkData.BirchbarkHealth.title}
                        position={WorkData.BirchbarkHealth.position} 
                        description={WorkData.BirchbarkHealth.description} 
                        image={WorkData.BirchbarkHealth.image} 
                        link={WorkData.BirchbarkHealth.link}
                        chips={WorkData.BirchbarkHealth.chips}
                    />
                    <WorkCard 
                        title={WorkData.LinkUp.title}
                        position={WorkData.LinkUp.position} 
                        description={WorkData.LinkUp.description} 
                        image={WorkData.LinkUp.image} 
                        link={WorkData.LinkUp.link}
                        chips={WorkData.LinkUp.chips}
                    />
                    <WorkCard 
                        title={WorkData.Erickson.title}
                        position={WorkData.Erickson.position} 
                        description={WorkData.Erickson.description} 
                        image={WorkData.Erickson.image} 
                        link={WorkData.Erickson.link}
                        chips={WorkData.Erickson.chips}
                    />
                </div>
            </div>
        </section>
    );
};