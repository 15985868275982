import React from "react";
import ProjectCard from "./ProjectCard";
import Hamstar from "../images/Hamstar.png";
import ArcticMania from "../images/ArcticMania.png";
import TinkersTale from "../images/TinkersTale.png";

import MirrorMadness from "../images/MirrorMadness.png";


export default function Games() {

    const GamesData = {
        MirrorMadness:{
            title:"Mirror Madness",
            description:"Mirror Madness is a 2D platformer with a symmetric twist. Use the two available mirrors to make your way through the puzzle and get to the mineshaft to proceed through the levels. Complete all 10 levels to complete the game.",
            image:MirrorMadness,
            chips:["Unity", "C#", "Pixel Art", "Game Development", "Game Design"],
            githubLink:"https://github.com/oscoson/Mirror-Madness",
            itchIOLink:"https://potatoboi1320.itch.io/mirror-madness",
            liveLink: null
        },
        Hamstar:{
            title:"Hamstar",
            description:"Hamstar is a gravity-based pixel platformer where you must guide and navigate your little hamster through planets and obstacles until it reaches its destined circle of stars! This game won 1st place in the Queen's University 2022 Summer Game Jam.",
            image:Hamstar,
            chips:["Unity", "C#", "Pixel Art", "Game Development"],
            githubLink:"https://github.com/oscoson/Hamstar",
            itchIOLink:"https://oscosan.itch.io/hamstar",
            liveLink: null
        },
        ArcticMania:{
            title:"Arctic Mania",
            description:"Arctic Mania is a 2D top-down shooter where you play as a penguin and must fight off waves of enemies. Worked in a team of 8 to create this game.",
            image:ArcticMania,
            chips:["Unity", "C#", "Pixel Art", "Game Development", "Game Design"],
            githubLink:"https://github.com/oscoson/Arctic-Mania",
            itchIOLink:"https://oscosan.itch.io/arctic-mania",
            liveLink: null
        },
        TinkersTale:{
            title:"Tinker's Tale",
            description:"Tinker's Tale is a 2D game where you must defeat enemies in a proceduraly generated dungeon, beat the boss, collect loot, and upgrade your weapons. Worked in a team of 3 to create this game for my CISC 226 Game Design course.",
            image:TinkersTale,
            chips:["Unity", "C#", "Pixel Art", "Game Development"],
            githubLink:"https://github.com/CISC-226-W23/226-game-design-project-gdp-7",
            itchIOLink:"https://sky-king2.itch.io/tinkers-tale-version-17",
            liveLink: null
        },
    }

    return(
        <section id="projects" className="text-gray-400 bg-slate-900 body-font flex flex-col items-center h-fit mt-40">
            <div className="py-10 text-center w-[90%] lg:w-[80%] 2xl:w-[65%]">
                <div className="flex flex-col w-full mb-20">
                    <h2 className="text-xs text-indigo-400 tracking-widest font-medium title-font mb-1">GAMES</h2>
                    <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-white">Games I am proud to present</h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">A collection of my most recent game projects, many of which are Game Jam Winners</p>
                </div>

                <div className="text-white flex flex-row justify-center flex-wrap w-full">
                    <ProjectCard
                        title={GamesData.MirrorMadness.title}
                        description={GamesData.MirrorMadness.description}
                        image={GamesData.MirrorMadness.image}
                        chips={GamesData.MirrorMadness.chips}
                        github={GamesData.MirrorMadness.githubLink}
                        link={GamesData.MirrorMadness.liveLink}
                        itchIO={GamesData.MirrorMadness.itchIOLink}
                    />
                    <ProjectCard 
                        title={GamesData.Hamstar.title} 
                        description={GamesData.Hamstar.description} 
                        image={GamesData.Hamstar.image} 
                        chips={GamesData.Hamstar.chips}
                        github={GamesData.Hamstar.githubLink}
                        link={GamesData.Hamstar.liveLink}
                        itchIO={GamesData.Hamstar.itchIOLink}
                    />
                    <ProjectCard 
                        title={GamesData.ArcticMania.title} 
                        description={GamesData.ArcticMania.description} 
                        image={GamesData.ArcticMania.image} 
                        chips={GamesData.ArcticMania.chips}
                        github={GamesData.ArcticMania.githubLink}
                        link={GamesData.ArcticMania.liveLink}
                        itchIO={GamesData.ArcticMania.itchIOLink}
                    />
                    <ProjectCard 
                        title={GamesData.TinkersTale.title} 
                        description={GamesData.TinkersTale.description} 
                        image={GamesData.TinkersTale.image} 
                        chips={GamesData.TinkersTale.chips}
                        github={GamesData.TinkersTale.githubLink}
                        link={GamesData.TinkersTale.liveLink}
                        itchIO={GamesData.TinkersTale.itchIOLink}
                    />
                </div>
            </div>
        </section>
    )
}